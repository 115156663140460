import React, {useEffect, useState} from 'react';
import {formatDate, getArticleLinks} from "../utils/articleUtil";
import {Image} from "react-datocms";
import Navigate from "../components/navigate";
import readingTime from '@danieldietrich/reading-time';

const ArticlePreview = props => {
    const [articleLinks, setArticleLinks] = useState(getArticleLinks(props.data));

    useEffect(() => {
        if (!props.data) {
            return;
        }
        setArticleLinks(getArticleLinks(props.data));
    }, [props.data])

    const imageUrl = () => {
        let url = `${props.data.cover.url}?`;
        let qualityGiven = false;
        if (props.imageMeta) {
            const keys = Object.keys(props.imageMeta);
            keys.forEach((key) => {
                url += `${key}=${props.imageMeta[key]}&`;
                !qualityGiven && (qualityGiven = key === 'q')
            });
        }
        if (!qualityGiven) {
            url += 'q=50';
        }
        return url;
    }

    const imageComponent = () => {
        return <figure className="teaser-card__figure">
            <Navigate href={articleLinks.getReadMore()}>
                {
                    props.imageFromUrl
                        ?
                        <img className="teaser-card__image" src={imageUrl()}/>
                        :
                        <Image classname={"teaser-card__image"} lazyLoad={props.lazyLoad || props.lazyLoad === undefined} data={props.data.cover.responsiveImage}/>
                }
            </Navigate>
        </figure>
    }

    const readTime = () =>{
        const {minutes, word} = readingTime(props.data.content.replace(/<blockquote[\s\S]*?<\/blockquote>/g, ''));
        return minutes;
    }

    return (
        <div className={`placeholder--teaser-card ${props.customClass ? props.customClass : ''}`} key={props.data.slug}
             style={props.style} data-datocms-noindex>
            <div className="teaser-card">
                {!props.noImage && props.data.cover?.url && !props.wrappedImg && imageComponent()}
                <div className="teaser-card__body">
                    {!props.noImage && props.data.cover?.url && props.wrappedImg && imageComponent()}
                    {!props.noFlag && <Navigate className="teaser-card__flag" href={articleLinks.categoryPath()}>{articleLinks.category.title}</Navigate>}
                    <Navigate className="teaser-card__title" href={articleLinks.getReadMore()}>{props.data.heading}</Navigate>
                    {props.publishedAt && <time>{formatDate(props.data.publishedAt, props.publishedAtFormat)}</time>}
                    {props.publishedAt && <span className={'reading-time'}> · {readTime()} min read</span>}
                    {!props.noBody && <>
                        <p className="teaser-card__text">{props.data.excerpt}</p>
                        {!props.noReadMore && <Navigate className="read-more" href={articleLinks.getReadMore()}>Read More</Navigate>}
                    </>}
                    {!props.noAuthor && <>
                    <span className="byline">By <Navigate
                        href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</Navigate>
                        {!props.noPublishedDate && <time className={'date'}>{formatDate(props.data.publishedAt, 'mmmm dd, yyyy')}</time>}
                    </span>
                    </>}
                </div>
            </div>
        </div>
    )
}
export default ArticlePreview;
