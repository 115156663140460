import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import AdLeftSticky from "../components/ads/adLeftSticky";
import AdRightSticky from "../components/ads/adRightSticky";
import PopularPosts from "../components/popularPosts";

function AboutUs(props) {
    return (
        <Layout>
            <main className="wrapper wrapper--article about-us-page" role="main" data-datocms-noindex>
                <AdLeftSticky/>
                <div>
                    <article className="article-content">
                        <div className="article-content__body">
                            <main className="article-content__main about-us-section">
                                <h1 className={"about-us-title"}>About <span>Pension Times</span></h1>
                                <div className={"about-us-content"}
                                     dangerouslySetInnerHTML={{__html: props.data.datoCMS.page.content}}></div>
                            </main>
                        </div>
                    </article>
                    <article className="article-content">
                        <div className="article-content__body">
                            <main className="article-content__main authors-section">
                                <h3 className="authors-section-title">Know our Authors</h3>
                                <div className="authors-section-body">
                                    {props.data.datoCMS.allAuthors.map(author => {
                                        return <div className="author-details">
                                            <div className="author-card__image">
                                                <img alt={author.name}
                                                     src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto?.url}?w=128&h=128&fit=crop` : `/images/logos/acronym.png`}/>
                                            </div>
                                            <div>{author.name}</div>
                                        </div>
                                    })}
                                </div>

                            </main>
                        </div>
                    </article>
                </div>
                <PopularPosts/>
                <AdRightSticky/>
            </main>
        </Layout>
    );
}

export const query = graphql`
  query Authors {
    datoCMS {
      page(filter: {slug: {eq: "about-us"}}) {
        content
        slug
      }
      allAuthors(first: "100") {
          name
          slug
          profilePhoto {
            url
          }
        }
    }
  }
`

export default AboutUs;