import React from "react";
import Navigate from "../navigate";

function CategoryTitle(props) {
    const render = () => {
        return <div className="popular-posts-top" data-datocms-noindex>
            <div className="heading">{props.title}</div>
            <div className="icon">></div>
        </div>;
    }
    return <>
        {props.link
            ? <Navigate href={props.link} className="popular-posts-top-link">
                {render()}
            </Navigate>
            : render()
        }
    </>;
}

export default CategoryTitle;